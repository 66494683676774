<template>
  <div class="cont sc-width">
    <Spin v-if="queryFactoryLoading" fix></Spin>
<!--    <div class="top">-->
<!--      <div class="back-request" @click="back('factory')">-->
<!--        <div class="pic"><img src="@/assets/back.png" alt="" /></div>-->
<!--        <div class="word">Return to List</div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="content">
      <!--      <div class="titleWrap">-->
      <!--        <h3 class="title">{{ $route.query.fname || create.factory_name }}</h3>-->
      <!--      </div>-->
      <div class="wrap">
        <div class="desc" v-if="create.id">
          <h4>Basic Info</h4>

          <h5>Logo</h5>
          <div class="logoWrap">
            <div class="logoUp">
              <img alt="" :src="create.avatar" />
            </div>
            <Button
              v-if="imageUploading"
              :disabled="true"
              icon="md-refresh"
              type="primary"
              ghost
            >
              Uploading ...
            </Button>
            <Button
              v-else
              type="primary"
              icon="md-refresh"
              ghost
              @click="$refs.file.click()"
            >
              <input
                v-show="false"
                type="file"
                ref="file"
                @change="handleUpload($event)"
                accept="image/*"
              />
              Replace Logo
            </Button>
            <p class="tip" style="color:#999;font-size:12px;margin-top:-15px">
              NOTE: Photos should be at least 250 px width by 150 px height.
            </p>
          </div>

          <h5>Factory Name</h5>
          <Input type="text" v-model="create.factory_name" />

          <h5>Factory Address</h5>
          <Input type="text" v-model="create.address" />

          <div class="addrWrap">
            <div class="list">
              <h5>City</h5>
              <Input type="text" v-model="create.city" />
            </div>
            <div class="list">
              <h5>State / Provinc3</h5>
              <Input type="text" v-model="create.state" />
            </div>
            <div class="list">
              <h5>Country</h5>
              <Select
                filterable
                v-model="create.country"
                placeholder="Select a country"
              >
                <Option
                  v-for="item in countryList"
                  :value="item.id"
                  :key="item.id"
                  >{{ item.name }}</Option
                >
              </Select>
            </div>
          </div>

          <h5>Factory Description</h5>
          <!-- <Input
            type="textarea"
            :rows="6"
            v-model="create.factory_description"
          /> -->
          <div class="editWrap" style="height:265px;">
            <quill-editor
              v-model="create.description"
              ref="myQuillEditor"
              style="height:200px;"
            >
            </quill-editor>
          </div>

          <div class="cateWrap">
            <div class="list">
              <h5>Main Product Category</h5>
              <Select
                v-model="create.main_product_category"
                placeholder="select one category"
              >
                <OptionGroup label="Botanical">
                  <Option value="Botanical">Botanical</Option>
                </OptionGroup>

                <OptionGroup label="Non-Botanical">
                  <Option
                    v-for="item in cateList['Non-Botanical']"
                    :key="item"
                    :value="item"
                    >{{ item }}</Option
                  >
                </OptionGroup>
              </Select>
            </div>
            <div class="listNull"></div>
            <div class="list">
              <h5>Certifications</h5>
              <Select
                filterable
                multiple
                v-model="create.document"
                :max-tag-count="3"
                placeholder="Attach a file"
              >
                <Option
                  v-for="item in documentList"
                  :value="item.id"
                  :key="item.id"
                  >{{ item.name }}</Option
                >
              </Select>
            </div>
          </div>

          <h5>Warehouse Locations</h5>
          <div class="composeWrap">
            <div
              class="compose"
              v-for="(item, index) in create.warehouse"
              :key="index"
            >
              <div class="delIt">
                <span>Warehouse {{ index + 1 }}</span>
                <Icon
                  type="md-trash"
                  @click="removeItem('create', 'warehouse', index)"
                />
              </div>
              <div class="wareContent">
                <div class="wareList">
                  <Input v-model="item.city" placeholder="CITY" />
                </div>
                <div class="wareList">
                  <Input v-model="item.state" placeholder="STATE" />
                </div>
                <div class="wareList">
                  <Select
                    filterable
                    v-model="item.country"
                    placeholder="Select a country"
                  >
                    <Option
                      v-for="item in countryList"
                      :value="item.id"
                      :key="item.id"
                      >{{ item.name }}</Option
                    >
                  </Select>
                </div>
              </div>
            </div>
            <p class="addHouse">
              <span
                @click="
                  addItem('create', 'warehouse', {
                    city: null,
                    state: null,
                    country: null
                  })
                "
                >+ Add a warehouse</span
              >
            </p>
          </div>

          <h4 style="margin: 10px 0 20px">Featured Products</h4>
          <Table
            :loading="loading"
            :columns="factoryColumns"
            :data="productsLists"
          >
            <template slot-scope="{ row, index }" slot="start">
              <img
                alt=""
                v-if="row.is_featured === 0"
                src="@/assets/product/icon_start1.png"
                class="iconstart"
                @click="toFeatured(row.id, index, 1)"
              />
              <img
                alt=""
                v-else-if="row.is_featured === 1"
                src="@/assets/product/icon_start2.png"
                class="iconstart"
                @click="toFeatured(row.id, index, 0)"
              />
            </template>

            <template slot-scope="{ row: item }" slot="product">
              <router-link :to="`/products/${item.id}`" class="proLink">
                <div class="proAvatar">
                  <img
                    alt=""
                    :src="item.avatar[0].url"
                    v-if="item.avatar.length"
                  />
                </div>
                <span>{{ item.marketing_name }}</span>
              </router-link>
            </template>

            <template slot="factory">
              {{ create.factory_name }}
            </template>

            <template slot-scope="{ row: item }" slot="status">
              <span>{{ item.status_desc }}</span>
            </template>
          </Table>

          <div class="page">
            <Page
              v-if="factoryPage.total > 0"
              :page-size="factoryPage.limit"
              :page-size-opts="[5, 10, 15, 20]"
              :total="factoryPage.total"
              show-sizer
              show-elevator
              show-total
              @on-change="pageChange"
              @on-page-size-change="pageSizeChange"
            />
          </div>
        </div>
      </div>
    </div>
    <div  class="form-foot"></div>
    <div style="text-align:center">
      <Button
        v-if="create.id"
        :loading="createLoading"
        type="primary"
        @click="createSubmit"
        style="width:200px;margin:30px auto 40px;"
        >Save Changes</Button
      >
      <Button type="primary" v-else style="width:200px;margin:10px auto 350px;"
        >Loading...</Button
      >
    </div>
  </div>
</template>

<script>
import api from "@/api";
const {
  _editFactory,
  _country,
  _queryDocumentList,
  _queryFactory,
  _queryProductRelatedInfo,
  _queryFeaturedFactory
} = api;
import S3 from "aws-s3";

const uploader = new S3({
  bucketName: "connexima",
  dirName: "documents",
  region: "us-west-1",
  accessKeyId: "AKIA6KR6UZLLVZYGDEZM",
  secretAccessKey: "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"
});

export default {
  data() {
    return {
      queryFactoryLoading: false,
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],

      documentList: [],
      countryList: [],
      cateList: [],
      createLoading: false,
      loading: false,
      imageUploading: false,
      imageFile: null,
      videoUploading: false,
      videoFile: null,
      create: {
        avatar: "",
        factory_name: null,
        phone: null,
        fda_number: null,
        zip_code: null,
        address: null,
        city: null,
        state: null,
        country: null,
        document: [],
        warehouse: [],
        featured_products: []
      },
      company: {},
      productsLists: [],
      factoryColumns: [
        {
          title: " ",
          slot: "start",
          width: 60
        },
        {
          title: "Product Name",
          slot: "product"
        },
        {
          title: "Factory",
          slot: "factory"
        },
        {
          title: "Type",
          key: "product_type"
        },
        {
          title: "Status",
          width: 150,
          align: "center",
          slot: "status"
        }
      ],
      factoryPage: {
        page: 1,
        limit: 10,
        total: 0
      }
    };
  },
  mounted() {
    const id = this.$route.params.id;
    this.id = id;

    this.queryFactoryLoading = true;
    _queryFactory({ id })
      .then(response => {
        const {
          data: {
            address,
            city,
            country,
            document,
            factory_name,
            fda_number,
            phone,
            zip_code,
            state
          }
        } = response;

        // this.create = response.data;

        // this.create.warehouse = response.data.warehouses;
        var allwarehouses = response.data.warehouses;
        if (allwarehouses && allwarehouses.length > 0) {
          allwarehouses.forEach((val, index) => {
            this.create.warehouse[index] = {
              city: val.city,
              state: val.state,
              country: val.country
            };
          });
        }

        this.company = response.data.company;

        this.create.avatar = response.data.avatar && response.data.avatar.url;
        this.create.main_product_category = response.data.main_product_category;
        if (response.data.featured_products.length) {
          this.create.featured_products = response.data.featured_products.map(
            item => item.id
          );
        } else {
          this.create.featured_products = [];
        }
        this.create.id = response.data.id;
        this.create.description = response.data.description;
        this.create.factory_name = factory_name;
        this.create.fda_number = fda_number;
        this.create.phone = phone;
        this.create.zip_code = zip_code;
        this.create.address = address;
        this.create.city = city;
        this.create.state = state;
        this.create.country = country;
        this.create.document = document.map(item => item.id);
      })
      .catch(error => {
        console.log(error);
      })
      .finally(() => {
        this.queryFactoryLoading = false;
      });

    _country()
      .then(response => {
        const { data } = response;
        this.countryList = data;
      })
      .catch(error => {
        console.log(error);
      });

    _queryProductRelatedInfo().then(response => {
      const { data } = response;
      this.cateList = data.product_type;
      this.categories = data.product_tag;
    });

    _queryDocumentList({
      limit: 999,
      type: "Factory Certification"
    })
      .then(response => {
        const {
          data: { data }
        } = response;

        this.documentList = data;
      })
      .catch(error => {
        console.log(error);
      });

    this.getFactory();
  },

  methods: {
    back(type) {
      this.$router.push({ path: "/company-profile", query: { type: type } });
    },
    getFactory() {
      this.loading = true;
      var datas = {
        id: this.$route.params.id,
        page: this.factoryPage.page,
        limit: this.factoryPage.limit
      };
      _queryFeaturedFactory(datas)
        .then(response => {
          const { data } = response;
          this.productsLists = data.data;
          this.factoryPage.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createSubmit() {
      this.createLoading = true;

      var createCopy = JSON.parse(JSON.stringify(this.create));
      if (createCopy.featured_products.length === 0)
        createCopy.featured_products = [""];
      _editFactory({
        id: this.id,
        ...createCopy
      })
        .then(() => {
          this.$Message.success("Edit factory success");
          this.$router.push(
            `/factories/${this.create.id}/detail?fname=${this.create.factory_name}`
          );
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.createLoading = false;
        });
    },
    handleUpload(e, type) {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          this.$Message.error("File size cannot exceed 20MB");
        } else {
          if (type === "video") {
            this.videoFile = file;
          } else {
            this.imageFile = file;
          }
          this.upload(type);
        }
      }
    },

    upload(type) {
      let file;
      if (type === "video") {
        this.videoUploading = true;
        file = this.videoFile;
      } else {
        this.imageUploading = true;
        file = this.imageFile;
      }

      uploader
        .uploadFile(file)
        .then(data => {
          if (type === "video") {
            this.create.video = data.location;
          } else {
            this.create.avatar = data.location;
          }
          console.log(data.location);
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          if (type === "video") {
            this.videoUploading = false;
          } else {
            this.imageUploading = false;
          }
        });
    },
    addItem(form, name, obj) {
      this[form][name].push(obj);
    },
    removeItem(form, name, index) {
      this[form][name].splice(index, 1);
    },
    pageChange(page) {
      this.factoryPage.page = page;
      this.getFactory();
    },
    pageSizeChange(limit) {
      this.factoryPage.limit = limit;
      this.getFactory();
    },

    toFeatured(id, index, tag) {
      if (tag === 1) {
        this.create.featured_products.push(id);
        this.$set(this.productsLists[index], "is_featured", 1);
      } else {
        this.create.featured_products.forEach((val, index) => {
          if (val === id) {
            this.create.featured_products.splice(index, 1);
            this.$set(this.productsLists[index], "is_featured", 0);
          }
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.back {
  color: @primary-color;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background: #fff;
}
.cont {
  /*background: #fff;*/
  position: relative;
  margin: 78px auto 0 auto;

  .content {
    position: relative;
    top: 40px;
    background: #fff;
    border-radius: 15px;
    .titleWrap {
      position: relative;
      .title {
        color: #000;
        font-size: 20px;
        text-align: center;
        padding: 25px 0;
      }
      .edit {
        position: absolute;
        top: 25px;
        right: 20px;
      }
    }
    .wrap {
      padding: 20px;

      .desc {
        /*width: 80%;*/
        min-width: 750px;
        margin: 0 auto;
        border: 1px solid #dfdfdf;
        border-radius: 8px;
        padding: 15px;
        h4 {
          font-size: 15px;
          color: #555;
          padding-bottom: 10px;
          border-bottom: 1px solid #ddd;
        }
        .logoWrap {
          .logoUp {
            img {
              height: 120px;
              border: 1px solid #f2f2f2;
            }
          }
          .ivu-btn {
            margin: 20px 0;
          }
        }

        h5 {
          color: #888;
          font-weight: normal;
          padding: 25px 0 10px;
        }
        .descCont {
          color: #333;
          padding: 4px;
          border-bottom: 1px solid #ddd;
          background-color: #f9f9f9;
        }
        .addrWrap {
          display: flex;
          justify-content: space-between;
          .list {
            flex: 0 0 32%;
          }
        }
        .cateWrap {
          display: flex;
          justify-content: space-between;
          .list {
            flex: 0 0 49%;
          }
        }
        .houseLists {
          display: flex;
          list-style: none;
          font-size: 12px;
          flex-wrap: wrap;
          li {
            flex: 0 0 48%;
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: 10px 15px 15px;
            margin-bottom: 20px;
            &:nth-child(n) {
              margin-right: 2%;
            }
          }
          .tit {
            color: #888;
            padding: 4px 0;
          }
          .name {
            color: #333;
            padding: 4px;
            font-size: 13px;
            border-bottom: 1px solid #ddd;
            background-color: #f9f9f9;
          }
        }
        .itemProduct {
          margin-bottom: 20px;
          border: 1px solid #ccc;
          background: #fff;
          box-shadow: 2px 4px 6px rgba(51, 51, 51, 0.149019607843137);
          border-radius: 5px;
          .img-box {
            width: 100%;
            height: 0px;
            position: relative;
            padding-bottom: 56.25%;
            overflow: hidden;
            border-bottom: 1px solid #ccc;
            background: #eee;
            .avatar {
              display: block;
              width: 100%;
            }
          }

          .name {
            padding: 10px;
            min-height: 80px;
            border-bottom: 1px solid #ccc;
            color: #666;
            .product-name {
              font-size: 16px;
              color: #333;
              font-weight: bold;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .product-type {
              font-size: 13px;
              color: #333;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .tags {
            padding: 10px;
            height: 50px;
            display: flex;
            align-items: center;
            overflow: hidden;
            .tag {
              color: #fff;
              border-radius: 3px;
              font-size: 12px;
              margin-right: 4px;
              height: 25px;
              display: flex;
              align-items: center;
              padding: 0 5px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .composeWrap {
    .compose {
      margin-bottom: 20px;
      .delIt {
        border: 1px solid #ddd;
        padding: 6px 10px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        span {
          color: #666;
        }
        .ivu-icon {
          font-size: 22px;
          color: red;
          float: right;
          cursor: pointer;
        }
      }
      .wareContent {
        display: flex;
        justify-content: space-around;
        border: 1px solid #ddd;
        padding: 5px 10px;
        padding-top: 15px;
        border-top: none;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        .wareList {
          flex: 0 0 31%;
          padding-bottom: 10px;
        }
        .ivu-form-item {
          margin-bottom: 15px;
        }
      }
    }
  }

  .addHouse {
    color: @primary-color;
    text-align: right;
    span {
      cursor: pointer;
    }
  }
}

.page {
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
}
.iconstart {
  width: 20px;
  vertical-align: middle;
  cursor: pointer;
}
.proLink {
  color: @primary-color;
  font-weight: bold;
  .proAvatar {
    display: inline-block;
    width: 30px;
    height: 30px;
    overflow: hidden;
    vertical-align: middle;
    margin-right: 5px;
    img {
      height: 100%;
    }
  }
  span {
    vertical-align: middle;
  }
}
.cRed {
  color: #ed4014;
}
.cGreen {
  color: #19be6b;
}
.top {
  .back-request {
    width: 220px;
    height: 40px;
    background: #ef7f21;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    margin-top: 50px;
    margin-bottom: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    top: 39px;
    .pic {
      width: 8px;
      height: 10px;
      margin-right: 10px;
      margin-top: 2px;
      img {
        width: 20px;
        height: 20px;
        position: relative;
        top: 8px;
        left: 0px;
      }
    }

    .word {
      position: relative;
      top: 9px;
      font-size: 20px;

      font-weight: 500;
      color: #ffffff;
      line-height: 18px;
    }
  }
}
.box {
  margin: 90px 0 10px 0;
  /*width: 800px;*/
  /*padding: 20px 30px;*/
  box-shadow: 0 2px 16px grey, 0 0 1px grey, 0 0 1px grey;
  .factory-form {
    width: 1130px;
    margin: 30px auto 0 auto;
  }
  .title {
    height: 90px;
    background: linear-gradient(90deg, #a25d72, #2d3b81);
    opacity: 0.9;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 28px;

    font-weight: 700;
    color: #ffffff;
    line-height: 18px;
    padding: 35px 0 0 48px;
  }
  .upload {
    width: 100%;
    display: inline-block;
    .upload-success {
      display: flex;
      align-items: center;
      .text {
        margin-left: 4px;
      }
    }
  }
  .logoWrap {
    .logoUp {
      .logoImg {
        height: 120px;
        margin-top: 36px;
        font-size: 12px;
        line-height: 120px;
        img {
          height: 100%;
          border: 1px solid #ddd;
        }
      }
      .logonull {
        font-size: 12px;
        text-align: center;
        line-height: 120px;
        margin-top: 36px;
        width: 120px;
        height: 120px;
        border: 1px dashed #ddd;
      }
    }
    .ivu-btn {
      margin: 20px 0;
    }
  }
}
.form-foot{
  height: 30px;
}
</style>
<style scoped>
.form-box >>> .ivu-card-bordered {
  border: none;
}

.factory-form >>> .ivu-form-item-label {
  font-size: 16px;

  font-weight: 400;
  color: #292929;
  line-height: 18px;
  width: 200px;
}
.factory-form >>> .ivu-form-item-content {
  margin-left: 200px;
}
.ivu-btn-primary {
  width: 200px;
  height: 34px;
  background: linear-gradient(90deg, #f5a210, #ef8126);
  border-radius: 5px;
  font-size: 18px;

  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
}
.ivu-steps {
  width: 1150px;
  margin: 0 auto;
  justify-content: space-between;
}
/*.ivu-form-item{*/
/*  width: 1200px;*/
/*}*/
</style>
